<template>
  <div v-loading="loading" class="auth-login h-full w-full">
    <LoginFaildDialog v-if="faild" />
  </div>
</template>

<script>
import { LoginByUserAuthGrant } from '@/api/auth'
import LoginFaildDialog from './components/LoginFaildDialog.vue'
export default {
  name: 'AuthLogin',
  components: { LoginFaildDialog },
  data: () => ({
    loading: true,
    faild: false,
  }),
  async mounted () {
    const orgCode = location.host.split('.')[0]
    const code = this.$route.query.code
    const [res, err] = await LoginByUserAuthGrant({
      orgCode,
      code,
    })
    if (err) {
      this.loading = false
      this.faild = true
      return
    }
    this.$store.commit('auth/SET_TOKEN', res.token)
    this.$router.replace('/')
  },
}
</script>

<style lang="postcss" scoped>

</style>
